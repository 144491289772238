.animatedBackground {
  background: linear-gradient(320deg, #211e56, #4f46e5, #9894ea);
  background-size: 600% 600%;
  animation: Background 6s ease infinite;
}

@keyframes Background {
  0%{background-position:0% 5%}
  50%{background-position:100% 96%}
  100%{background-position:0% 5%}
}